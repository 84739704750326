import WebComponent from "../webcomponent.js"

const template = (obj) => html`
  <div id="qrcode"></div>
`

customElements.define('app-qrcode', class extends WebComponent {
  init() {
  }

  render() {
    this.shadow.innerHTML = template(this)
    this.qrcodeDiv = this.shadow.querySelector("#qrcode")
  }

  setUrl(url) {
    new QRCode(this.qrcodeDiv, url);
  }
});
